import React, { MouseEvent } from 'react';

interface ButtonProps {
  children?: React.ReactChild | React.ReactChild[];
  onClick?: (event: MouseEvent) => void;
  selected?: boolean;
  style?: any;
}

export const Button: React.FC<ButtonProps> = props => {
  if (props.style) {
    const style: any = {
        padding: 10,
        background: '#fff0',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '00',
        borderRadius: 7,
        fontSize: 20,
        marginRight: 15,
        color: 'black',
    }

    for (const key in props.style) {
        style[key] = props.style[key];
    }

    return (
        <span
          onClick={props.onClick}
          className="button"
          style={style}
        >
          {props.children}
        </span>
      );

  }
  if (props.selected) {
    return (
      <span
        onClick={props.onClick}
        className="button"
        style={{
          padding: 10,
          background: '#222',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'black',
          borderRadius: 7,
          fontSize: 20,
          marginRight: 15,
          color: 'white',
        }}
      >
        {props.children}
      </span>
    );
  }
  return (
    <span
      onClick={props.onClick}
      className="button"
      style={{
        padding: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 7,
        fontSize: 20,
        marginRight: 15,
      }}
    >
      {props.children}
    </span>
  );
};
