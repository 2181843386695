import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Base } from './pages/Base';
import { Login } from './pages/Login';
import { Teams } from './pages/Teams';

import Toaster from 'react-hot-toast';

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/teams'} exact>
          <Teams />
        </Route>
        <Route path={'/login'} exact>
          <Login />
        </Route>
        <Route>
          <Base />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
