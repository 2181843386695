import { Endpoint } from '../internals/common/Endpoint';
import * as t from 'io-ts';

export const TeamsAPI = {
  identify: Endpoint.public(
    t.type({ accessToken: t.string, tokenType: t.string }),
    t.type({ authKey: t.string, username: t.string, discriminator: t.string }),
  ),
  //
  getInfo: Endpoint.public(
    t.type({ authKey: t.string }),
    t.type({
      team: t.union([
        t.undefined,
        t.type({
          name: t.string,
          tagline: t.string,
          projectTrack: t.union([t.literal('DOWNTOWN'), t.literal('STUDIO'), t.literal('CAMPUS')]),
          members: t.array(t.type({ username: t.string, discriminator: t.string })),
          invites: t.array(t.type({ username: t.string, discriminator: t.string })),
        }),
      ]), // undefined if user has no team
      invites: t.array(
        t.type({
          teamId: t.string,
          name: t.string,
          tagline: t.string,
          projectTrack: t.union([t.literal('DOWNTOWN'), t.literal('STUDIO'), t.literal('CAMPUS')]),
        }),
      ), // may be empty
    }),
  ),
  //
  createTeam: Endpoint.public(
    t.type({
      authKey: t.string,
      name: t.string,
      tagline: t.string,
      projectTrack: t.union([t.literal('DOWNTOWN'), t.literal('STUDIO'), t.literal('CAMPUS')]),
    }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * edits the current user's team by replacing values. name, tagline, and projectTrack are all optional - whatever
   * isn't provided is left unchanged.
   */
  editTeam: Endpoint.public(
    t.intersection([
      t.type({ authKey: t.string }),
      t.partial({
        name: t.string,
        tagline: t.string,
        projectTrack: t.union([t.literal('DOWNTOWN'), t.literal('STUDIO'), t.literal('CAMPUS')]),
      }),
    ]),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  deleteTeam: Endpoint.public(
    t.type({ authKey: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  //
  /**
   * sends an invite to join the current user's team to the specified user
   * invalid if the current user isn't on a team
   */
  sendInvite: Endpoint.public(
    t.type({ authKey: t.string, username: t.string, discriminator: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * revokes an existing invite that the current user has sent
   */
  revokeInvite: Endpoint.public(
    t.type({ authKey: t.string, username: t.string, discriminator: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * accepts the invite to the specified team
   */
  acceptInvite: Endpoint.public(
    t.type({ authKey: t.string, teamId: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * rejects the invite to the specified team
   */
  rejectInvite: Endpoint.public(
    t.type({ authKey: t.string, teamId: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * removes a user from the current user's team
   */
  removeUser: Endpoint.public(
    t.type({ authKey: t.string, username: t.string, discriminator: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
  /**
   * leaves the current user's team. deletes the team if the current user is the only member left
   */
  leaveTeam: Endpoint.public(
    t.type({ authKey: t.string }),
    t.undefined, // endpoint will return success: true | false + an optional error message
  ),
};
