import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Loading } from '../components/Loading';
import { ApiClient } from '../index';
import { setAuthData } from '../utils/authData';

interface GrantResult {
  access_token: string;
  token_type: string;
  scope: string;
}

const useUrlGrantResult = (): Partial<GrantResult> => {
  // eslint-disable-next-line no-restricted-globals
  const fragments = location.href.split('#')[1];
  if (fragments === undefined) return {};
  return fragments.split('&').reduce<Partial<GrantResult>>((acc, entry) => {
    const entryParts = entry.split('=');
    acc[entryParts[0] as keyof GrantResult] = entryParts[1];
    return acc;
  }, {});
};

const PageCenter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background: #f901dd;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Title = styled.div`
  color: white;

  font-size: 30px;
  font-weight: bold;
  font-family: Lato, sans-serif;

  margin-bottom: 10px;
`;

const Error = styled.div`
  color: white;

  font-size: 20px;
  font-family: Lato, sans-serif;

  margin-top: 10px;
`;

export const Login: React.FC = () => {
  const history = useHistory();
  const grantResult = useUrlGrantResult();

  const [error, setError] = useState<undefined | string>(undefined);

  const invokeIdentify = async (grantResult: Partial<GrantResult>) => {
    if (
      grantResult.access_token === undefined ||
      grantResult.token_type === undefined ||
      grantResult.scope === undefined
    ) {
      setError('Unable to authorize user');
    } else {
      // success
      const result = await ApiClient.identify.send({
        accessToken: grantResult.access_token,
        tokenType: grantResult.token_type,
      });
      if (!result.success) {
        if (result.messageForUser) {
          setError(result.message);
        } else {
          setError('An unknown error has occurred');
        }
      } else {
        setAuthData(result.body);
        history.replace('/teams');
      }
    }
  };

  useEffect(() => {
    invokeIdentify(grantResult);
  }, []);

  if (error) {
    return (
      <PageCenter>
        <Title>Error</Title>
        <Error>{error}</Error>
      </PageCenter>
    );
  } else {
    return (
      <PageCenter>
        <Title>Logging In</Title>
        <Loading size={50} color={'white'} />
      </PageCenter>
    );
  }
};
