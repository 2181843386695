import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { readAuthData } from '../utils/authData';

interface IdpParams {
  client_id: string;
  redirect_uri: string;
  scope: string;
  grant_type: string;
}

const params = {
  client_id: '889725661005086740',
  redirect_uri: 'https://teams.dubhacks.co/login',
  scope: 'identify',
  grant_type: 'token',
};

export const createIdpLoginUrl = (params: IdpParams) => {
  return `https://discord.com/api/oauth2/authorize?client_id=${params.client_id}&redirect_uri=${encodeURIComponent(
    params.redirect_uri,
  )}&response_type=${params.grant_type}&scope=${params.scope}`;
};

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

const PageCenter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background: #f901dd;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Title = styled.div`
  color: white;

  font-size: 30px;
  font-weight: bold;
  font-family: Lato, sans-serif;

  margin-bottom: 10px;
`;

const LogInButton = styled.button`
  background: #3131e8;
  margin-top: 10px;
  padding: 10px 15px;
  color: white;

  outline: none;
  border: none;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    background: #2a2ac5;
  }
`;

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

export const Base: React.FC = () => {
  const history = useHistory();
  const authData = readAuthData();
  if (authData) {
    history.push('/teams');
  }

  const redirectLogin = (): never => {
    // eslint-disable-next-line no-restricted-globals
    location.href = createIdpLoginUrl(params);
    throw new Error('could not navigate to IDP');
    // this function does not return
  };

  return (
    <PageCenter>
      <Title>DubHacks '21 Teams</Title>
      <LogInButton onClick={redirectLogin}>Log In with Discord</LogInButton>
    </PageCenter>
  );
};
