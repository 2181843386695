import React from 'react';
import { ImLoop2 } from 'react-icons/all';
import styled from 'styled-components';

export const Loading = styled(ImLoop2)`
  animation: loop infinite linear 2s;
  margin: 10px;
  
  @keyframes loop {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
