import * as Either from 'fp-ts/Either';
import { AuthenticatedEndpoint, Endpoint, PublicEndpoint } from '../common/Endpoint';
import { ClientOptions } from './client';

interface Response<R> {
  success: true;
  body: R;
}

interface Error {
  success: false;
  message: string;
  messageForUser: boolean;
}

export type ResponseOrError<R> = Response<R> | Error;

class BaseClientEndpoint<Request, Response> {
  private readonly endpoint: Endpoint<Request, Response>;
  private readonly clientOptions: ClientOptions;
  private readonly endpointUrl: string;

  protected constructor(endpoint: Endpoint<Request, Response>, clientOptions: ClientOptions, endpointUrl: string) {
    this.endpoint = endpoint;
    this.clientOptions = clientOptions;
    this.endpointUrl = endpointUrl;
  }

  protected doRequest = async (requestBody: unknown): Promise<ResponseOrError<Response>> => {
    try {
      const res = await fetch(`${this.clientOptions.host}:${this.clientOptions.port}${this.endpointUrl}`, {
        body: JSON.stringify(requestBody),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (res.status === 403) {
        await this.clientOptions.onAuthFailure?.();
        return {
          success: false as const,
          message: 'Not logged in!',
          messageForUser: true,
        };
      } else if (res.status !== 200) {
        return {
          success: false as const,
          message: await res.text(),
          messageForUser: true,
        };
      }
      //

      let json;
      try {
        json = await res.json();
      } catch (e: any) {
        return {
          success: true as const,
          body: {} as any
        }
      }

      const decodedRes = this.endpoint.responseCodec.decode(json);
      if (Either.isLeft(decodedRes)) {
        throw Error('Invalid response from server - does not match specified response codec');
      }
      return {
        success: true as const,
        body: decodedRes.right,
      };
    } catch (e: any) {
      return {
        success: false as const,
        message: e.message,
        messageForUser: false,
      };
    }
  };
}

export class AuthenticatedClientEndpoint<Request, Response> extends BaseClientEndpoint<Request, Response> {
  constructor(endpoint: AuthenticatedEndpoint<Request, Response>, clientOptions: ClientOptions, endpointUrl: string) {
    super(endpoint, clientOptions, endpointUrl);
  }

  send = async (body: Request, token: string): Promise<ResponseOrError<Response>> => {
    return this.doRequest({ token, body });
  };
}

export class PublicClientEndpoint<Request, Response> extends BaseClientEndpoint<Request, Response> {
  constructor(endpoint: PublicEndpoint<Request, Response>, clientOptions: ClientOptions, endpointUrl: string) {
    super(endpoint, clientOptions, endpointUrl);
  }

  send = async (body: Request): Promise<ResponseOrError<Response>> => {
    return this.doRequest(body);
  };
}
