const LOCALSTORAGE_AUTH_KEY = 'authKey';

interface AuthData {
  authKey: string;
  username: string;
  discriminator: string;
}

export const readAuthData = (): AuthData | undefined => {
  const raw = localStorage.getItem(LOCALSTORAGE_AUTH_KEY);
  if (!raw) return undefined;
  try {
    return JSON.parse(raw);
  } catch (e) {
    clearAuthData();
    return undefined;
  }
};

export const setAuthData = (authData: AuthData) => {
  localStorage.setItem(LOCALSTORAGE_AUTH_KEY, JSON.stringify(authData));
};

export const clearAuthData = () => {
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEY);
};
