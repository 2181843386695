import * as t from 'io-ts';

export enum UserRole {
  Admin = 'ADMIN',
  Danger = 'DANGER',
}

export class Endpoint<Request, Response> {
  requestCodec: t.Type<Request>;
  responseCodec: t.Type<Response>;

  protected constructor(requestCodec: t.Type<Request>, responseCodec: t.Type<Response>) {
    this.requestCodec = requestCodec;
    this.responseCodec = responseCodec;
  }

  static public = <Request, Response>(
    requestCodec: t.Type<Request>,
    responseCodec: t.Type<Response>,
  ): PublicEndpoint<Request, Response> => {
    return new PublicEndpoint<Request, Response>(requestCodec, responseCodec);
  };

  static authenticated = <Request, Response>(
    requestCodec: t.Type<Request>,
    responseCodec: t.Type<Response>,
    allowedRoles: UserRole[],
  ) => {
    return new AuthenticatedEndpoint<Request, Response>(requestCodec, responseCodec, allowedRoles);
  };
}

export class PublicEndpoint<Request, Response> extends Endpoint<Request, Response> {
  constructor(requestCodec: t.Type<Request>, responseCodec: t.Type<Response>) {
    super(requestCodec, responseCodec);
  }
}

export class AuthenticatedEndpoint<Request, Response> extends Endpoint<Request, Response> {
  allowedRoles: UserRole[];

  constructor(requestCodec: t.Type<Request>, responseCodec: t.Type<Response>, allowedRoles: UserRole[]) {
    super(requestCodec, responseCodec);
    this.allowedRoles = allowedRoles;
  }
}
