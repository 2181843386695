import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { TeamsAPI } from './api/definition/teams';
import { createClient } from './api/internals/client/client';
import { App } from './App';
import { clearAuthData } from './utils/authData';

export const ApiClient = createClient(TeamsAPI, {
  host: 'https://bot.dubhacks.co',
  port: 9000,
  onAuthFailure: () => {
    clearAuthData();
    // eslint-disable-next-line no-restricted-globals
    location.replace('/login');
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
