import React, { useState, useEffect, createRef } from 'react';
import { Button } from '../components/Button';
import toast, { Toaster } from 'react-hot-toast';

import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { clearAuthData, readAuthData } from '../utils/authData';

import { ApiClient } from '..';

type maybeTrack = 'DOWNTOWN'|'STUDIO'|'CAMPUS'|'';
type track = 'DOWNTOWN'|'STUDIO'|'CAMPUS';

interface member {
  username: string;
  discriminator: string;
}

interface invite {
  teamId: string;
  name: string;
  tagline: string;
  projectTrack: track;
}

export const Teams: React.FC = () => {
  const [ createJoinTeam, setCreateJoinTeam ] = useState<string>('');
  const [ teamTrack, setTeamTrack ] = useState<maybeTrack>('');

  const [ invitedUsers, setInvitedUsers ] = useState<member[]>([]);
  const [ pendingInvitations, setPendingInvitations ] = useState<invite[]>([]);

  const [ teamTrackError, setTeamTrackError ] = useState<string>('');
  const [ teamNameError, setTeamNameError ] = useState<string>('');
  const [ teamTaglineError, setTeamTaglineError ] = useState<string>('');
  const [ teamInviteError, setTeamInviteError ] = useState<string>('');

  const teamNameField = React.useRef<HTMLInputElement>(null);
  const teamTaglineField = React.useRef<HTMLInputElement>(null);
  const inviteUsernameField = React.useRef<HTMLInputElement>(null);

  const [ teamMembers, setTeamMembers ] = useState<member[]>([]);

  const [ submitting, setSubmitting ] = React.useState<boolean>(false);
  const [ submitError, setSubmitError ] = React.useState<string>('');
  const [ hasCreatedTeam, setHasCreatedTeam ] = React.useState<boolean>(false);
  
  /*
  return (
    <div className='container'>Team setup is closed now. Contact DH staff if you need help.</div>
  )
  */
  
  
  const userHistory = useHistory();
  const authData = readAuthData();

  const checkResultForMessage = (result: any) => {
    return false;
  }

  useEffect(() => {
    getUserInfo();
  }, [])

  if (!authData) {
    userHistory.push('/');
    return (
      <div>
        You're not logged in. <Link to={'/'}>Click here</Link> to log in.
      </div>
    );
  }

  const clearErrors = () => {
    setTeamTrackError('');
    setTeamNameError('');
    setTeamTaglineError('');
    setSubmitError('');
    setTeamInviteError('');
  }

  const getUserInfo = () => {
    if (!authData) {
      userHistory.push('/');
      return (
        <div>
          You're not logged in. <Link to={'/'}>Click here</Link> to log in.
        </div>
      );
    }
    
    ApiClient.getInfo.send({
      authKey: authData.authKey
    }).then((result: any) => {
      console.log('getINfo', result);
      const body = result.body;
      if (body.team !== undefined) {
        setHasCreatedTeam(true);
        if (teamNameField.current) {
          teamNameField.current.value = body.team.name;
        }

        if (teamTaglineField.current) {
          teamTaglineField.current.value = body.team.tagline;
        }

        setTeamTrack(body.team.projectTrack);
        setTeamMembers(body.team.members);
        setInvitedUsers(body.team.invites);
      } else {
        setHasCreatedTeam(false);
      }
      setPendingInvitations(body.invites);

    }).catch((error) => { 

    })
  }

  const submit = () => {
    if (teamNameField === null || teamNameField.current === null) {
      return;
    }

    if (teamTaglineField === null || teamTaglineField.current === null) {
      return;
    }

    let error = false;
    // perform input validation
    if (teamNameField.current.value.length > 25) {
      setTeamNameError('team name must be 25 characters or less!');
      error = true;
    } else if (teamNameField.current.value.length === 0) {
      setTeamNameError('please enter a team name.');
      error = true;
    } else {
      setTeamNameError('');
    }

    if (teamTaglineField.current.value.length > 125) {
      setTeamTaglineError('tagline name must be 125 characters or less!');
      error = true;
    } else if (teamTaglineField.current.value.length === 0) {
      setTeamTaglineError('please enter a team tagline.');
      error = true;
    } else {
      setTeamTaglineError('');
    }

    if (teamTrack === '') {
      setTeamTrackError('please select a track to participate in.');
      error = true;
    } else {
      setTeamTrackError('');
    }

    if (!error) {
      setSubmitting(true);
      const method = hasCreatedTeam ? ApiClient.editTeam : ApiClient.createTeam;
      const result = method.send({
        authKey: authData.authKey,
        name: teamNameField.current.value,
        tagline: teamTaglineField.current.value,
        projectTrack: (teamTrack as track)
      }).then((result: any) => {
        setSubmitting(false);
        console.log(result);
        if (checkResultForMessage(result) || result.success) {
          clearErrors();
          toast.success("updated team info!");
          getUserInfo();
        } else {
          setSubmitting(false);
          setSubmitError(result.message);
        }
      }).catch((result) => {
        setSubmitting(false);
        setSubmitError('something went wrong!');
      })

      console.log('submit result', result);
    }
  }

  let invitationsElement: any = <span>You have no pending invitations currently. <i>You will need to refresh the page to see new invitations.</i></span>
  if (pendingInvitations.length > 0) { 
    invitationsElement = pendingInvitations.map((invite, idx) => {
      return <div style={{display: 'flex', alignItems: "center", alignContent: "center", marginBottom: 10}}>
          <span style={{color: 'black', marginRight: 15}}>{"->"} {invite.name}</span> <Button style={{color: "#49AA70", borderColor: "#49AA70", padding: 5}} onClick={()=>{
            ApiClient.acceptInvite.send({
              authKey: authData.authKey,
              teamId: invite.teamId
            }).then((result) => {
              console.log(result);
              if (result.success|| checkResultForMessage(result)) {
                clearErrors();
                getUserInfo();
              }
            })
          }}>Accept</Button> <Button style={{color: "#ff0505", borderColor: "#ff0505", padding: 5}} onClick={()=>{
            ApiClient.rejectInvite.send({
              authKey: authData.authKey,
              teamId: invite.teamId
            }).then((result) => {
              if (result.success|| checkResultForMessage(result)) {
                clearErrors();
                getUserInfo();
              }
            })
          }}>Decline</Button>
        </div>
    })
  }

  if (hasCreatedTeam) {
    return (
      <div className="container">
        <div><Toaster position='top-center'/></div>
        <div className='h1' style={{marginBottom: 50}}>
          <Button style={{borderColor: "#ff0505", color: "#ff0505", marginRight: 20}}onClick={()=>{
            clearAuthData();
            userHistory.push('/');
          }}>Sign out</Button>
          <Button style={{borderColor: "#ff0505", color: "#ff0505"}}onClick={()=>{
            ApiClient.leaveTeam.send({
              authKey: authData.authKey
            }).then((result) => {
              console.log(result);
              if (result.success|| checkResultForMessage(result)) {
                clearErrors();
                getUserInfo();
              }
            })
          }}>Leave team</Button>
        </div>
        <br/><br/>
    
        <div className='h1' style={{}}>Hi, {authData.username}#{authData.discriminator}!<br/><br/></div>
        <div className='h2' style={{}}>
        This is your team info. Feel free to change your info throughout the event. <i>You may need to refresh this page to view changes other teammates have made.</i>
        <br/><br/>
    
        
        </div>
        
        <div className='h3_5' style={{}}>
        
          <div style={{fontWeight: 600}}>Track</div>
          <div style={{display:'flex', marginTop: 5}}>
              <Button onClick={()=>setTeamTrack('STUDIO')} selected={teamTrack==='STUDIO'}>studio</Button>
              <Button onClick={()=>setTeamTrack('DOWNTOWN')} selected={teamTrack==='DOWNTOWN'}>downtown</Button>
              <Button onClick={()=>setTeamTrack('CAMPUS')} selected={teamTrack==='CAMPUS'}>campus</Button>
          </div>
    
          <br/><br/>

          <div style={{fontWeight: 600}}>Team name (25 chars max)</div>
          <input placeholder="epic team name" className='h3' style={{ marginRight: 10, marginTop:5}} ref={teamNameField}></input>
          {teamNameError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 5}}>{teamNameError}</div> : <br/>}
    
          <br/><br/>
    
          <div style={{fontWeight: 600}}>Team tagline (125 chars max)</div>
          <input placeholder="wonderful team tagline" className='h3' style={{ marginRight: 10, marginTop:5, width: "80%"}} ref={teamTaglineField}></input>
          {teamTaglineError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 5}}>{teamTaglineError}</div> : <br/>}
          <br/><br/>

          
          <div>
            <Button onClick={submit}>{submitting ? "committing..." : "edit ->"}</Button>
            </div>
          {submitError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 15}}>{submitError}</div> : <br/>}
          <br/>
          <div style={{marginTop: 25, fontWeight: 600}}>Team members</div>
          {teamMembers.map((member, idx) => {
            return <div className='h2' style={{}}>{member.username}#{member.discriminator}</div>
          })}<br/><br/>

          <div style={{fontWeight: 600}}>Sent/send invites</div>

          <div style={{display: 'flex'}}>
            <div>
              {invitedUsers.map((member, idx) => {
                return <div className='h2' style={{ marginTop: 10}}>{"->"} {member.username}#{member.discriminator} <Button style={{padding: 4, color: "#ff0505", borderColor:"#ff0505"}} onClick={()=>{
                  ApiClient.revokeInvite.send({
                    authKey: authData.authKey,
                    username: member.username,
                    discriminator: member.discriminator
                  }).then((result) => {
                    console.log(result);
                    if (result.success || checkResultForMessage(result)) {
                      clearErrors();
                      toast.success("removed invitation for " + member.username + "#" + member.discriminator);
                    
                      const invitedUsers_ = invitedUsers.slice(0);
                      invitedUsers_.splice(idx, 1);
                      setInvitedUsers(invitedUsers_);
                    }

                  }).catch((error) => {console.log(error)})
                }}>cancel invitation</Button></div>
              })}
            </div>
            
          </div>
          {invitedUsers.length > 0 ? <br/> : <></>}
              
          <div style={{display:'flex', marginTop: 5}}>
              <input placeholder="username#0000" className='h3' style={{ marginRight: 10, width: "50%"}} ref={inviteUsernameField}></input>
            <Button onClick={()=>{
              if (inviteUsernameField != null && inviteUsernameField.current != null) {
                // make request to invite here
                // only add to invitedUsers if the request succeeded
                const invitedUsers_ = invitedUsers.slice(0);
                const [username, discriminator] = inviteUsernameField.current.value.split('#');

                if (!username || !discriminator) {
                  setTeamInviteError('make sure to enter in both a username and a discriminator!');
                  return;
                }

                const invitedUser: member = {
                  username,
                  discriminator
                }

                ApiClient.sendInvite.send(
                  {
                    authKey: authData.authKey,
                    username,
                    discriminator
                  }
                ).then((result) => {
                  console.log(result);
                  if (result.success || checkResultForMessage(result)) {
                    clearErrors();
                    invitedUsers_.push(invitedUser);
                    setInvitedUsers(invitedUsers_);
                    toast.success('invited ' + inviteUsernameField.current?.value);
                    setTeamInviteError('');
                    if (inviteUsernameField.current) {
                      inviteUsernameField.current.value = '';
                    }
                  } else {
                    setTeamInviteError(result.message);
                    toast.error('invite failed!');
                  }
                }).catch((error) => {console.log(error)});

            }}}>invite -&gt;</Button>
            
          </div>
          {teamInviteError ? <div style={{marginTop: 10, color: "#ff0505"}}>{teamInviteError}</div> : <></>}
          <br/><br/>

        </div>
      </div>
      );
  }

  return (
  <div className="container">
    <div><Toaster position='top-right'/></div>
    <div className='h1' style={{ marginBottom: 50}}>
          <Button style={{borderColor: "#ff0505", color: "#ff0505"}}onClick={()=>{
            clearAuthData();
            userHistory.push('/');
          }}>Sign out</Button>
        </div>


    <div className='h1' style={{}}>Invitations</div>
    <div className='h2' style={{ color: "#aaa"}}>
          {invitationsElement}
          
        </div>
    <br/><br/>

    <div className='h1' style={{}}>Hi, {authData.username}#{authData.discriminator}!<br/><br/></div>
    <div className='h2' style={{}}>
    This is the DH‘21 team management portal.
    <br/><br/>

    If you would like to create a team, click the button below. Otherwise, invitations to other teams will pop up automatically!
    <br/><br/>
    </div>
    <div style={{display:'flex'}}>
      <Button onClick={()=>setCreateJoinTeam('create')} selected={createJoinTeam==='create'}>🏁 create a team</Button>
    </div>

    {createJoinTeam==='create'?
    <div className='h3_5' style={{}}>
      <div className='h2'><br/><hr></hr><br/>Fill out this form below to create your team! Don't worry, you can always change your answers later :)<br/><br/></div>

      <div>Track</div>
      <div style={{display:'flex', marginTop: 5}}>
          <Button onClick={()=>setTeamTrack('STUDIO')} selected={teamTrack==='STUDIO'}>studio</Button>
          <Button onClick={()=>setTeamTrack('DOWNTOWN')} selected={teamTrack==='DOWNTOWN'}>downtown</Button>
          <Button onClick={()=>setTeamTrack('CAMPUS')} selected={teamTrack==='CAMPUS'}>campus</Button>
      </div>
      {teamTrackError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 5}}>{teamTrackError}</div> : <br/>}

      <br/>

      <div>Team name (25 chars max)</div>
      <input placeholder="epic team name" className='h3' style={{ marginRight: 10, marginTop:5}} ref={teamNameField}></input>
      {teamNameError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 5}}>{teamNameError}</div> : <br/>}

      <br/><br/>

      <div>Team tagline (125 chars max)</div>
      <input placeholder="wonderful team tagline" className='h3' style={{ marginRight: 10, marginTop:5, width: "80%"}} ref={teamTaglineField}></input>
      {teamTaglineError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 5}}>{teamTaglineError}</div> : <br/>}
      <br/><br/><br/>
      <div>
        <Button onClick={submit}>{submitting ? "submitting..." : "submit ->"}</Button>
        </div>
      {submitError ? <div className='h3_5' style={{ color: '#fe0505', marginTop: 15}}>{submitError}</div> : <br/>}
    </div>
    : <></>}
  

  </div>
  );
}
