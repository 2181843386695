import { APIDefinition } from '../common/APIDefinition';
import { AuthenticatedEndpoint, Endpoint, PublicEndpoint } from '../common/Endpoint';
import { ClientAPI } from './ClientAPI';
import { AuthenticatedClientEndpoint, PublicClientEndpoint } from './ClientEndpoint';

export interface ClientOptions {
  host: string;
  port: number;
  onAuthFailure?: () => void | Promise<void>;
}

export const createClient = <D extends APIDefinition>(api: D, options: ClientOptions): ClientAPI<D> => {
  const recursiveInitialize = <D extends APIDefinition>(api: D, pathContext = ''): any => {
    const res: any = {};
    Object.entries(api).forEach(([key, value]: [keyof D, APIDefinition | Endpoint<any, any>]) => {
      if (value instanceof PublicEndpoint) {
        res[key] = new PublicClientEndpoint(value, options, `${pathContext}/${key}`);
      } else if (value instanceof AuthenticatedEndpoint) {
        res[key] = new AuthenticatedClientEndpoint(value, options, `${pathContext}/${key}`);
      } else {
        res[key] = recursiveInitialize(value as APIDefinition, `${pathContext}/${key}`);
      }
    });
    return res as ClientAPI<D>;
  };
  return recursiveInitialize(api);
};
